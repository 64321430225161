
.navbar-logo {
  height: 50px;
  padding: 10px 30px 0px 0;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}


// #22364A
// #A0ACBD
// #6C7888
// #482C37
// #7A5A65


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.Navbar {
  &.ui.inverted.segment {
    background-color: #22364a;
  }
  .ui.secondary.inverted.pointing.menu {
    border: none;
  }
  .ui.button {
    background-color: #6C7888;
    color: #fff;
    height: 40px!important;
    &.inverted {
     background-color: #fff!important;
     color: #222;
    }
    
  }

  .ui.selection.dropdown {
    font-weight: 700;
    font-size: 15px;
    height: 40px!important;
    background-color: white;
    border: none;
    &.dark {
     background-color: #6C7888;
     color: #fff;
    }
  }
}

.footer {
  &.segment.inverted {
    background-color: #482C37;
  }
}

a.ui.button.download-button {
  background-color: #22364A;
  color: #fff;
}

a.ui.button.link-button {
  background-color: #7A5A65;
  color: #fff;
}

.ui.text-block {
  p {
    font-size: 1.33em;
  }
}

